"use strict";

var _seamlessScrollPolyfill = require("seamless-scroll-polyfill");
require("@formatjs/intl-listformat/polyfill");
require("@formatjs/intl-listformat/locale-data/en");
require("@formatjs/intl-listformat/locale-data/nl");
require("@formatjs/intl-listformat/locale-data/de");
require("@formatjs/intl-pluralrules/polyfill");
require("@formatjs/intl-pluralrules/locale-data/en");
require("@formatjs/intl-pluralrules/locale-data/nl");
require("@formatjs/intl-pluralrules/locale-data/de");
require("intersection-observer");
require("array-flat-polyfill");
// TODO: These will always be used now. We can use {shouldPolyfill} to polyfill only when necessary/

/**
 * This files runs at the very beginning (even before React and Next.js core)
 */

(0, _seamlessScrollPolyfill.polyfill)();